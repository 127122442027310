<template>
  <div style="background: #F2F3F8; width: 100%;height: 100%;padding-top: 20px;">
    <div class="address">
      <div class="titles">
        <i
          class="el-icon-arrow-left"
          style="font-size: 18px;cursor:pointer;"
          @click="goBack"
        ></i>
        地址管理
      </div>
      <div style="background: #F2F3F8;height: 20px;width:100%"></div>
      <div style="padding: 15px">
        <div class="addressTitle">
          <div class="addAddress">
            <el-button style="width: 100%" type="primary" @click="showDialog()"
              >新增地址</el-button
            >
          </div>
        </div>
        <el-table :data="tableData" border>
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column prop="recipients" label="收货人"></el-table-column>
          <el-table-column
            prop="recipientsCellphone"
            label="收货人电话"
          ></el-table-column>
          <el-table-column prop="region" label="地区"></el-table-column>
          <el-table-column prop="postCode" label="邮编"></el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
          <el-table-column prop="defaultFlag" label="是否默认">
            <template slot-scope="scope">
              <span v-if="scope.row.defaultFlag == 1">是</span>
              <span v-if="scope.row.defaultFlag == 0">否</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="warning"
                @click="showDialog(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="deleteItem(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-row class="pagination">
          <el-col :span="24">
            <el-pagination
              align="right"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current"
              :page-size="pageSize"
              layout="total"
              :total="totalPage"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
      <!-- 新增 编辑弹窗 -->
      <el-dialog
        v-dialogDrag
        title="地址管理"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="400px"
        @close="resetForm"
      >
        <el-form
          ref="form"
          :model="addressItem"
          :rules="rules"
          label-width="80px"
        >
          <el-form-item label="收货人" prop="recipients" ref="recipients">
            <el-input v-model="addressItem.recipients"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="recipientsCellphone">
            <el-input v-model="addressItem.recipientsCellphone"></el-input>
          </el-form-item>
          <el-form-item label="地区" prop="addressName">
            <el-input v-model="addressItem.addressName"></el-input>
          </el-form-item>
          <el-form-item label="邮编" prop="postCode">
            <el-input v-model="addressItem.postCode"></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="addressItem.address"></el-input>
          </el-form-item>
          <el-form-item label="是否默认" prop="defaultFlag">
            <el-radio-group v-model="addressItem.defaultFlag">
              <el-radio :label="0">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="confirmAddress()"
            :disabled="btnDisable"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!-- <dataDialog ref="dataDialog" /> -->
    </div>
  </div>
</template>
<script>
import dataDialog from "./component/dataDialog";
// import site_address } from "@/assets/js/address/address.js";
export default {
  components: {
    dataDialog
  },
  data() {
    return {
      btnDisable: false,
      tableData: [
        // {
        //   recipients: "张明",
        //   cellphone: "132 212 21892",
        //   region: "天津",
        //   postcode: "300000",
        //   address: "天津市 东丽区",
        //   defaultCode: 1
        // }
      ],
      rules: {
        postCode: [
          { required: true, message: "请输入邮编", trigger: "change" }
        ],
        recipients: [
          { required: true, message: "请输入收货人", trigger: "change" }
        ],
        recipientsCellphone: [
          { required: true, message: "请输入收货人电话", trigger: "change" }
        ],
        addressName: [
          { required: true, message: "请输入地区", trigger: "change" }
        ],
        defaultFlag: [
          { required: true, message: "请选择是否默认", trigger: "change" }
        ],
        address: [
          { required: true, message: "请填写详细地址", trigger: "change" }
        ]
      },
      addressItem: {
        recipients: "",
        recipientsCellphone: "",
        region: "",
        postCode: "",
        addressName: "",
        defaultCode: ""
      },
      current: 1,
      pageSize: 10,
      totalPage: null,
      dialogVisible: false,
      saveType: "add"
    };
  },
  mounted() {
    // console.log(site_address, "areaList");
    this.getAddressData();
  },
  methods: {
    goBack() {
      this.$router.push("/userOrSafety");
    },
    // 获取收货地址
    getAddressData() {
      protocolNJP.param_getUserAddress.param.page = this.current - 1;
      protocolNJP.param_getUserAddress.param.size = this.pageSize;
      http.postFront(protocolNJP.param_getUserAddress).then(response => {
        var { code, value, message } = response.data;
        console.log(response);
        if (Number(code) === 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].region) {
              value[i].address = JSON.parse(value[i].region).address;
              if (JSON.parse(value[i].region).region.length > 0) {
                value[i].region = JSON.parse(value[i].region).region.join(",");
              } else {
                value[i].region = "";
              }
            }
          }
          this.tableData = value;
          console.log(this.tableData, "this.tableData");
          this.totalPage = value.length;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAddressData();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getAddressData();
    },
    showDialog(item) {
      // this.$refs.dataDialog()
      if (item) {
        console.log(item, "编辑");
        this.addressItem = item;
        this.saveType = "edit";
      } else {
        this.saveType = "add";
        if (this.$refs["form"] !== undefined) {
          this.addressItem = {
            recipients: "",
            recipientsCellphone: "",
            region: "",
            postCode: "",
            addressName: "",
            defaultCode: ""
          };
          console.log(this.$refs["recipients"]);
          // this.$refs['recipients'].resetField();
          this.$refs["form"].clearValidate();
        }
      }
      this.dialogVisible = true;
    },
    deleteItem(item) {
      this.$confirm("请确认是否删除该地址？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolNJP.param_deleteUserAddress.param.addressId = item.addressId;
          console.log(item);
          http.postFront(protocolNJP.param_deleteUserAddress).then(response => {
            var { value, code, message } = response.data;
            if (Number(code) === 0) {
              this.current = 1;
              this.getAddressData();
              this.$EL_MESSAGE("删除成功");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    // 新增地址
    confirmAddress() {
      this.btnDisable = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.saveType == "add") {
            // this.btnDisable = true
            let peisong = [this.addressItem.addressName];
            let region = {
              region: peisong,
              address: this.addressItem.address,
              areaCode: 110101
            };
            // 新增
            protocolNJP.param_saveUserAddress.param.addressName =
              this.addressItem.addressName + this.addressItem.address;
            protocolNJP.param_saveUserAddress.param.region = JSON.stringify(
              region
            );
            protocolNJP.param_saveUserAddress.param.defaultFlag = this.addressItem.defaultFlag;
            protocolNJP.param_saveUserAddress.param.postCode = this.addressItem.postCode;
            protocolNJP.param_saveUserAddress.param.recipients = this.addressItem.recipients;
            protocolNJP.param_saveUserAddress.param.recipientsCellphone = this.addressItem.recipientsCellphone;
            http.postFront(protocolNJP.param_saveUserAddress).then(response => {
              var { code, value, message } = response.data;
              if (Number(code) === 0) {
                this.clearData();
                this.dialogVisible = false;
                this.current = 1;
                this.getAddressData();
                this.$EL_MESSAGE("成功");
                this.btnDisable = false;
              } else {
                this.btnDisable = false;
                this.$EL_MESSAGE(message);
              }
            });
          } else {
            // this.btnDisable = true
            // 编辑
            protocolNJP.param_updateUserAddress.param.addressId = this.addressItem.addressId;
            protocolNJP.param_updateUserAddress.param.addressName = this.addressItem.addressName;
            protocolNJP.param_updateUserAddress.param.region = this.addressItem.region;
            protocolNJP.param_updateUserAddress.param.defaultFlag = this.addressItem.defaultFlag;
            protocolNJP.param_updateUserAddress.param.postCode = this.addressItem.postCode;
            protocolNJP.param_updateUserAddress.param.recipients = this.addressItem.recipients;
            protocolNJP.param_updateUserAddress.param.recipientsCellphone = this.addressItem.recipientsCellphone;
            http
              .postFront(protocolNJP.param_updateUserAddress)
              .then(response => {
                var { code, value, message } = response.data;
                if (Number(code) === 0) {
                  this.clearData();
                  this.dialogVisible = false;
                  this.current = 1;
                  this.getAddressData();
                  this.$EL_MESSAGE("成功");
                  this.btnDisable = false;
                } else {
                  this.$EL_MESSAGE(message);
                  this.btnDisable = false;
                }
              });
          }
          // this.dialogVisible = false;
        } else {
          this.btnDisable = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    clearData() {
      this.addressItem = {
        recipients: "",
        recipientsCellphone: "",
        region: "",
        postCode: "",
        addressName: "",
        defaultCode: ""
      };
    },
    resetForm() {
      console.log("guanbi");
      this.$nextTick(() => {
        // this.$refs[formName].resetFields();
        this.$refs["form"].clearValidate();
      });
      // this.$refs['form'].resetFields()
    }
  }
};
</script>
<style scoped>
.address {
  width: 1200px;
  margin: 0 auto;
  min-height: 100%;
  /* padding: 0 15px; */
  /* margin-top: 20px; */
  background-color: #fff;
  height: 100%;
}
.addressTitle {
  margin-bottom: 10px;
  overflow: hidden;
}
.address .myaddress {
  float: left;
  line-height: 40px;
}
.addressTitle .myaddress p {
  font-size: 16px;
}
.address .addAddress {
  float: right;
}
.address .pagination {
  margin-top: 15px;
}
.titles {
  padding: 15px;
  font-size: 18px;
}
</style>
