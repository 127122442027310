<template>
    <el-dialog v-dialogDrag title="地址管理"   :close-on-click-modal="false" :visible.sync="dialogVisible" width="400px" @close="resetForm">
      <el-form ref="form" :model="addressItem" :rules="rules" label-width="80px">
        <el-form-item label="收货人" prop="recipients" ref="recipients">
          <el-input v-model="addressItem.recipients"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="recipientsCellphone">
          <el-input v-model="addressItem.recipientsCellphone"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-input v-model="addressItem.region"></el-input>
        </el-form-item>
        <el-form-item label="邮编" prop="postCode">
          <el-input v-model="addressItem.postCode"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="addressName">
          <el-input v-model="addressItem.addressName"></el-input>
        </el-form-item>
        <el-form-item label="是否默认" prop="defaultFlag">
          <el-radio-group v-model="addressItem.defaultFlag">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddress()">确 定</el-button>
      </span>
    </el-dialog>
</template>

<script>
export default {
  data() {
    return{
      rules: {
        recipients: [
          { required: true, message: "请输入收货人", trigger: "change" }
        ],
        recipientsCellphone: [
          { required: true, message: "请输入收货人电话", trigger: "change" }
        ],
        addressName: [{ required: true, message: "请输入地址", trigger: "change" }],
        defaultFlag: [
          { required: true, message: "请选择是否默认", trigger: "change" }
        ]
      },
      addressItem: {
        recipients: "",
        recipientsCellphone: "",
        region: "",
        postCode: "",
        addressName: "",
        defaultCode: ""
      },
      dialogVisible: false
    }
  },
  methods: {
    showDialog() {
      // console.log(this.$refs["form"])
      this.dialogVisible = true
    },
    resetForm() {
      console.log('guanbi')
       this.$nextTick(()=>{
        // this.$refs[formName].resetFields();
        this.$refs["form"].clearValidate();
      }) 
      // this.$refs['form'].resetFields()
    }
  }
}
</script>

<style>

</style>